import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

const asyncComponent = (importComponent: () => Promise<any>) => {
  const AsyncFunc = (props: any) => {
    const [Component, setComponent] = useState<ReactNode | null>(null);

    useEffect(() => {
      Nprogress.start();
      //return () => Nprogress.stop();
    }, []);

    useEffect(() => {
      console.log('running async effect');
      const doMount = async () => {
        const { default: Component } = await importComponent();
        if (!Component) {
          const test = await importComponent();
          console.log('Bad component import', test);
        }
        console.log('component', Component);
        Nprogress.done();
        setComponent(<Component {...props} />);
      };

      doMount().catch((e) => {
        console.log('error mounting', e);
        Nprogress.done();
        setComponent(
          <div>
            <div>error loading component</div>
            <div>{e.toString()}</div>
          </div>
        );
      });

      return () => {
        setComponent(null);
      };
    }, [props]);

    return (
      <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
        {Component ?? <div />}
      </ReactPlaceholder>
    );
  };

  return AsyncFunc;
};

const asyncNamedComponent = (importComponent: () => Promise<any>) => {
  const AsyncNamedFunc: React.FC<any> = (props) => {
    const [Component, setComponent] = useState<ReactNode | null>(null);

    useEffect(() => {
      Nprogress.start();
      //return () => Nprogress.stop();
    }, []);

    useEffect(() => {
      const doMount = async () => {
        const Component = await importComponent();
        Nprogress.done();
        setComponent(<Component {...props} />);
      };

      doMount().catch((e) => {
        console.log('error mounting', e);
        Nprogress.done();
        setComponent(
          <div>
            <div>error loading component</div>
            <div>{e.toString()}</div>
          </div>
        );
      });

      return () => {
        setComponent(null);
      };
    }, [props]);

    return (
      <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
        {Component ?? <div />}
      </ReactPlaceholder>
    );
  };

  return AsyncNamedFunc;
};

export { asyncComponent, asyncNamedComponent };
