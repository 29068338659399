import saMessages from '@app/i18n/locales/es_ES.json';
import antdSA from 'antd/lib/locale-provider/ca_ES';
// import appLocaleData from 'react-intl/locale-data/es';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'es',
  // data: appLocaleData,
};
export default saLang;
