import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import '@app/index.css';
import { ConfigProvider } from 'antd';
import React, { useEffect, useState } from 'react';

import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import config, {
  getCurrentLanguage,
} from './containers/LanguageSwitcher/config';
import useLanguageSwitcher from './containers/LanguageSwitcher/context';
import DashAppHolder from './dashAppStyle';
import Firebase from './helpers/firebase';
import { getCompany } from './helpers/utility';
import AppLocale from './i18n';
import { PublicRoutes } from './router';
import themes from './settings/themes';
// for apollo
//import ApolloClient from "apollo-boost";

const server_base = process.env.REACT_APP_API_SERVER;
//const server_base = "https://prodo-server.herokuapp.com"
console.log('server base', server_base);
const clientUrl = server_base + '/api/graphql';
// Instantiate required constructor fields
const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: clientUrl });
// @ts-ignore this works but typeings dont recognize a promise
const authMiddleware = new ApolloLink(async (operation, forward) => {
  // add the authorization to the headers
  const fireToken = await Firebase.token();
  const company = getCompany();
  console.log('request fire token', fireToken);
  operation.setContext({
    headers: {
      authorization: fireToken ? `Bearer ${fireToken}` : 'token bad',
      'company-id': company ? company.id : null,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
});

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage).locale || 'english'];

const DashApp2 = () => {
  const [loading, setLoading] = useState(true);
  const { language } = useLanguageSwitcher();

  useEffect(() => {
    const authSubscription = Firebase.auth().onAuthStateChanged((user) => {
      setLoading(false);
    });
    return () => {
      authSubscription();
    };
  }, []);

  const { locale } = language;

  if (loading) {
    return null;
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={locale} messages={currentAppLocale.messages}>
        <ThemeProvider theme={themes['themedefault']}>
          <ApolloProvider client={client}>
            <DashAppHolder>
              <PublicRoutes />
            </DashAppHolder>
          </ApolloProvider>
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default DashApp2;
