import type {
  CustomizedThemeNames,
  ThemeOption,
} from '@app/containers/ThemeSwitcher/config';
import { getCurrentTheme } from '@app/containers/ThemeSwitcher/config';
import type { ThemeSwitcherAction } from '@app/containers/ThemeSwitcher/reducer';
import ThemeSwitcherReducer, {
  ThemeSwitcherActionType,
} from '@app/containers/ThemeSwitcher/reducer';
import type { Dispatch, PropsWithChildren } from 'react';
import React, { createContext, useContext, useReducer } from 'react';

export interface ThemeSwitcherState {
  isActivated: boolean;
  coreTheme: ThemeOption;
  topbarTheme: ThemeOption;
  sidebarTheme: ThemeOption;
  layoutTheme: ThemeOption;
}

export const initialState: ThemeSwitcherState = {
  isActivated: false,
  coreTheme: getCurrentTheme('coreTheme'),
  topbarTheme: getCurrentTheme('topbarTheme'),
  sidebarTheme: getCurrentTheme('sidebarTheme'),
  layoutTheme: getCurrentTheme('layoutTheme'),
};

const ThemeSwitcherContext = createContext<{
  state: ThemeSwitcherState;
  dispatch: Dispatch<ThemeSwitcherAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const ThemeSwitcherProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(ThemeSwitcherReducer, initialState);

  return (
    <ThemeSwitcherContext.Provider value={{ state, dispatch }}>
      {children}
    </ThemeSwitcherContext.Provider>
  );
};

const useThemeSwitcher = () => {
  const context = useContext(ThemeSwitcherContext);

  if (context === undefined) {
    throw new Error(
      'useThemeSwitcher must be used within ThemeSwitcherContext'
    );
  }

  const { state, dispatch } = context;

  const switchActivation = () => {
    dispatch({
      type: ThemeSwitcherActionType.SWITCH_ACTIVATION,
    });
  };

  const changeTheme = (attribute: CustomizedThemeNames, themeName: string) => {
    // looks bad practice, should be in the reducer?
    const theme = getCurrentTheme(attribute, themeName);
    if (attribute === 'layoutTheme' && theme.backgroundColor) {
      const element = (document.querySelectorAll<HTMLElement>(
        '.isomorphicContent'
      )[0].style.backgroundColor = theme.backgroundColor);
    }
    dispatch({
      type: ThemeSwitcherActionType.CHANGE_THEME,
      attribute,
      theme,
    });
  };

  return {
    ...state,
    switchActivation,
    changeTheme,
  };
};

export default useThemeSwitcher;
