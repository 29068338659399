import type { Dispatch, PropsWithChildren } from 'react';
import React, { createContext, useContext, useReducer } from 'react';
import type { LanguageOption } from './config';
import config, { getCurrentLanguage } from './config';
import type { LanguageSwitcherAction } from './reducer';
import languageSwitcherReducer, { ActionType } from './reducer';

export interface LanguageSwitcherState {
  isActivated: boolean;
  language: LanguageOption;
}

export const initialState: LanguageSwitcherState = {
  isActivated: false,
  language: getCurrentLanguage(config.defaultLanguage || 'english'),
};

const LanguageSwitcherContext = createContext<{
  state: LanguageSwitcherState;
  dispatch: Dispatch<LanguageSwitcherAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const LanguageSwitcherProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(languageSwitcherReducer, initialState);

  return (
    <LanguageSwitcherContext.Provider value={{ state, dispatch }}>
      {children}
    </LanguageSwitcherContext.Provider>
  );
};

const useLanguageSwitcher = () => {
  const context = useContext(LanguageSwitcherContext);

  if (context === undefined) {
    throw new Error(
      'useLanguageSwitcher must be used within LanguageSwitcherContext'
    );
  }
  const { state, dispatch } = context;

  const switchActivation = () => {
    dispatch({
      type: ActionType.ACTIVATE_LANG_MODAL,
    });
  };

  const changeLanguage = (language: string) => {
    dispatch({
      type: ActionType.CHANGE_LANGUAGE,
      language: getCurrentLanguage(language),
    });
  };

  return {
    ...state,
    switchActivation,
    changeLanguage,
  };
};

export default useLanguageSwitcher;
