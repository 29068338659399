export default {
  apiUrl: 'http://yoursite.com/api/',
};
const siteConfig = {
  siteName: 'Prodo',
  siteIcon: 'ion-flash',
  footerText: 'Prodo ©2017 Created by RedQ, Inc',
};

const language = 'english';
const firebaseConfig = {
  apiKey: 'AIzaSyD1p8K-kkAjUhcg-2G1dkKy-mxmvjB0C-o',
  authDomain: 'prodo-time.firebaseapp.com',
  databaseURL: 'https://prodo-time.firebaseio.com',
  projectId: 'prodo-time',
  storageBucket: 'prodo-time.appspot.com',
  messagingSenderId: '255235714536',
  appId: '1:255235714536:web:b6e0a5d92e0d8db887c85d',
};
const googleConfig = {
  apiKey: '', //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: [],
};
const youtubeSearchApi = '';
export {
  siteConfig,
  language,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
};
