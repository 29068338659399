import React from 'react';
import {
  FaAddressCard,
  FaBuilding,
  FaChartLine,
  FaCube,
  FaCubes,
  FaFileInvoice,
  FaFileInvoiceDollar,
  FaHandshake,
  FaMountain,
  FaPalette,
  FaQuestionCircle,
  FaReceipt,
  FaStar,
  FaSuitcase,
  FaTasks,
  FaTshirt,
  FaUsers,
  FaUserTag,
} from 'react-icons/fa';
import { ModuleIconWrapper } from './moduleIcon.style';

const moduleInfo: any = {
  Account: {
    icon: FaBuilding,
    color: 'rgb(0, 159, 253)',
  },
  Deal: {
    icon: FaHandshake,
    color: 'rgb(68, 175, 105)',
  },
  Contact: {
    icon: FaAddressCard,
    color: 'rgb(160, 148, 237)',
  },
  AccountContact: {
    icon: FaUserTag,
    color: 'rgb(32, 44, 89)',
  },
  Lead: {
    icon: FaStar,
    color: 'rgb(248, 137, 98)',
  },
  Campaign: {
    icon: FaMountain,
    color: 'rgb(247, 129, 84)',
  },
  StatsDesignCode: {
    icon: FaCubes,
    color: 'rgb(255, 164, 0)',
  },
  Design: {
    icon: FaCubes,
    color: 'rgb(255, 164, 0)',
  },
  ArtCode: {
    icon: FaCube,
    color: 'rgb(76, 30, 79)',
  },
  Style: {
    icon: FaTshirt,
    color: 'rgb(247, 219, 167)',
  },
  Forecast: {
    icon: FaChartLine,
    color: 'rgb(248, 137, 98)',
  },
  ArtJob: {
    icon: FaPalette,
    color: 'rgb(8, 61, 119)',
  },
  TeamStore: {
    icon: FaUsers,
    color: 'rgb(244, 211, 94)',
  },
  PurchaseOrder: {
    icon: FaReceipt,
    color: 'rgb(254, 198, 1)',
  },
  SalesOrder: {
    icon: FaFileInvoice,
    color: 'rgb(100, 8, 211)',
  },
  Invoice: {
    icon: FaFileInvoiceDollar,
    color: 'rgb(238, 150, 75)',
  },
  NpsSurvey: {
    icon: FaStar,
    color: 'rgb(248, 137, 98)',
  },
  Case: {
    icon: FaSuitcase,
    color: 'rgb(242, 207, 91)',
  },
  Task: {
    icon: FaTasks,
    color: 'rgb(75, 192, 118)',
  },
};

interface moduleIconProps {
  type: string;
  size?: number;
  colorBackground?: boolean;
  colorIcon?: boolean;
}

export const ModuleIcon: React.FC<moduleIconProps> = ({
  type,
  size = 16,
  colorBackground = false,
}) => {
  const itemInfo = moduleInfo[type] || {};
  let Icon = itemInfo.icon || FaQuestionCircle;
  let color = itemInfo.color || 'white';
  let iconColor = color;
  let bgClass: string = '';

  let style: any = {};
  if (colorBackground) {
    style.backgroundColor = color;
    iconColor = 'white';
    bgClass = 'module-icon-background';
  }

  return (
    <ModuleIconWrapper className={`module-icon ${bgClass}`} style={style}>
      <Icon size={size} color={iconColor} />
    </ModuleIconWrapper>
  );
};
