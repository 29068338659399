import { useLazyQuery } from '@apollo/client';
import { useDebounce } from '@app/components/hooks/useDebounce';
import { ModuleListItem } from '@app/components/module/moduleListItem';
import { gql } from '@app/gqlgen';
import { AutoComplete, Empty, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

const { Option, OptGroup } = Select;

// const searchModules = [
//   'accounts',
//   'deals',
//   'contacts',
//   'accountContacts',
//   'designsConnection',
//   'purchaseOrdersConn',
//   'teamStoresConnection',
// ];

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const renderTitle = (moduleName: string) => {
  return (
    <span>{toTitleCase(moduleName.replace(/([A-Z])/g, ' $1').trim())}</span>
  );
};

const renderItem = (item: {
  __typename: string;
  id: string;
  [key: string]: any;
}) => {
  return {
    value: `${item.__typename}_${item.id}`,
    label: (
      <div className="crm-search-option">
        <ModuleListItem item={item} showType />
      </div>
    ),
  };
};

interface DisplayResult {
  label: React.ReactNode;
  options: { value: string; label: React.ReactNode }[];
}

export const AllSearch = () => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);
  const [isSearching, setIsSearching] = useState(false);
  const [displayResults, setDisplayResults] = useState<DisplayResult[]>([]);
  const [getSearch, { loading, data }] = useLazyQuery(GET_FULL_SEARCH, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      console.log('allSearch mapping modules', data);
      let results: DisplayResult[] = [];
      if (debouncedSearchText === '') {
        return;
      }
      const searchModules = Object.keys(data)
      for (let moduleName of searchModules) {
        let moduleSearchValues = data[moduleName];
        let moduleOptions: DisplayResult['options'] = [];

        if (moduleName === 'purchaseOrdersConn') {
          moduleSearchValues = data['purchaseOrdersConn']?.['nodes'];
        }
        if (moduleName === 'teamStoresConnection') {
          moduleSearchValues = data['teamStoresConnection']?.['nodes'];
        }
        if (moduleName === 'designsConnection') {
          moduleSearchValues = data['designsConnection']?.['nodes'];
        }
        if (!moduleSearchValues) {
          results.push({
            label: renderTitle(moduleName),
            options: moduleOptions,
          });
          continue;
        }
        for (const moduleItem of moduleSearchValues) {
          let updatedItem = JSON.parse(JSON.stringify(moduleItem));
          updatedItem.type = updatedItem.__typename;
          moduleOptions.push(renderItem(updatedItem));
        }
        results.push({
          label: renderTitle(moduleName),
          options: moduleOptions,
        });
      }
      /*
        const displayedOptions = results.map((x) => (
          <Option
            className="crm-search-option"
            key={`${x.__typename}_${x.id}`}
            value={`${x.__typename}_${x.id}`}
          >
            <ModuleListItem item={x} showType />
          </Option>
        ));
        */
      setDisplayResults(results);

      setIsSearching(false);
    },
    onError: (error) => {
      console.log('allSearch error', error);
      setIsSearching(false);
    },
  });

  useEffect(() => {
    if (debouncedSearchText === '') {
      console.log('ignoring search');
      setDisplayResults([]);
      setIsSearching(false);
    } else {
      console.log(`Querying for ${debouncedSearchText}`);
      getSearch({
        variables: {
          limit: 5,
          query: debouncedSearchText,
        },
      });
    }
  }, [debouncedSearchText, getSearch]);

  const onSelect = (data) => {
    console.log('onSelect', data);
  };

  const onSearch = (value) => {
    setIsSearching(true);
    setDisplayResults([]);
    setSearchText(value);
  };

  return (
    <AutoComplete
      onSelect={onSelect}
      onSearch={onSearch}
      options={displayResults}
      virtual={false}
      placeholder="Search..."
      value={searchText}
      className="crm-search-input"
      // @ts-ignore
      listHeight="85vh"
      popupClassName="crm-search-input-dropdown"
      dropdownMatchSelectWidth={true}
      notFoundContent={isSearching || loading ? <Spin /> : <Empty />}
    />
  );
};

const GET_FULL_SEARCH = gql(`
  query searchCrm($limit: Int, $query: String) {
    accounts(limit: $limit, query: $query) {
      id
      name
      country
      state
    }
    deals(limit: $limit, query: $query) {
      id
      name
      account {
        id
        name
      }
    }
    contacts(limit: $limit, query: $query) {
      id
      full_name
      email
    }
    accountContacts(limit: $limit, query: $query) {
      id
      account {
        id
        name
      }
      contact {
        full_name
      }
    }
    designsConnection(limit: $limit, query: $query) {
      nodes {
        id
        name
        code
        stats_designcode_id
      }
    }
    purchaseOrdersConn(limit: $limit, query: $query, include_children: false) {
      nodes {
        id
        name
      }
    }
    teamStoresConnection(limit: $limit, query: $query) {
      nodes {
        id
        name
      }
    }
  }
`);
