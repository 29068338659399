import React from 'react';

const rtl = document.getElementsByTagName('html')[0].getAttribute('dir');

const withDirection = <P extends object>(Component: React.ComponentType<P>) => {
  const directionHoc = (props: P) => {
    return <Component {...props} data-rtl={rtl} />;
  };
  return directionHoc;
};

export default withDirection;
export { rtl };
