// import { addLocaleData } from 'react-intl';
import type { Locale } from 'antd/lib/locale-provider';
import Salang from './entries/ar_SA';
import Enlang from './entries/en-US';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';
import Itlang from './entries/it_IT';
import Zhlang from './entries/zh-Hans-CN';

export interface LanguageData {
  messages: any;
  antd: Locale | null;
  locale: string;
}
export const enum LOCALE_CODE {
  ENGLISH = 'en',
  CHINESE = 'zh',
  ARABIC = 'sa',
  ITALIAN = 'it',
  SPANISH = 'es',
  FRENCH = 'fr',
}

const AppLocale: Record<LOCALE_CODE, LanguageData> = {
  en: Enlang,
  zh: Zhlang,
  sa: Salang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
};
// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.zh.data);
// addLocaleData(AppLocale.sa.data);
// addLocaleData(AppLocale.it.data);
// addLocaleData(AppLocale.es.data);
// addLocaleData(AppLocale.fr.data);

export default AppLocale;
