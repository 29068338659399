import WithDirection from '@app/settings/withDirection';
import { Input } from 'antd';
import {
  InputGroupWrapper,
  InputSearchWrapper,
  InputWrapper,
  TextAreaWrapper,
} from './styles/input.style';

const { Search, TextArea, Group } = Input;

const WDStyledInput = InputWrapper(Input);
const WDInputGroup = InputGroupWrapper(Group);
const WDInputSearch = InputSearchWrapper(Search);
const WDTextarea = TextAreaWrapper(TextArea);

export const IsoInput = WithDirection(WDStyledInput);
export const IsoInputGroup = WithDirection(WDInputGroup);
export const IsoInputSearch = WithDirection(WDInputSearch);
export const IsoTextarea = WithDirection(WDTextarea);
