import { IsoPopover } from '@app/components/uielements';
import IntlMessages from '@app/components/utility/intlMessages';
import Firebase from '@app/helpers/firebase';
import { clearCompany } from '@app/helpers/utility';
import React, { useState } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import TopbarDropdownWrapper from './topbarDropdown.style';

export const TopbarUser = (props: {}) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const logout = () => {
    Firebase.logout();
    history.push('/');
  };

  const changeCompany = () => {
    clearCompany();
    history.push('/companyselect');
  };

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      {/*
        <a className="isoDropdownLink">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink">
          <IntlMessages id="sidebar.feedback" />
        </a>
        <a className="isoDropdownLink">
          <IntlMessages id="topbar.help" />
				</a>
			*/}
      <Link to={'/dashboard/userSettings'} className="isoDropdownLink">
        Settings
      </Link>
      <button
        className="isoDropdownLink border-none"
        onClick={() => changeCompany()}
      >
        Switch Company
      </button>
      <button className="isoDropdownLink border-none" onClick={() => logout()}>
        <IntlMessages id="topbar.logout" />
      </button>
      <Link to={'/dashboard/resources'} className="isoDropdownLink">
        Resources
      </Link>
    </TopbarDropdownWrapper>
  );

  return (
    <IsoPopover
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={() => setVisible(!visible)}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <FaUserAlt className="topbar-icon" />
    </IsoPopover>
  );
};
