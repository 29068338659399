import { useQuery } from '@apollo/client';
import { IsoSelect } from '@app/components/uielements';
import { gql } from '@app/gqlgen';
import React, { useEffect, useState } from 'react';

interface SelectUserProps {
  onChange: (name: string, id: string | null) => void;
  defaultValue?: string | null;
  value?: string | null;
  name?: string;
  placeholder?: string;
}

export const SelectUser: React.FC<SelectUserProps> = ({
  name,
  defaultValue,
  value,
  placeholder,
  onChange,
}) => {
  const [curValue, setCurValue] = useState<string | null>(
    value ?? defaultValue ?? null
  );
  const [users, setUsers] = useState<any[]>([]);
  const { loading, error, data } = useQuery(GET_USERS, {
    onCompleted(data) {
      console.log('users data', data);
      if (!data || !data.users) {
        return;
      }
      setUsers(
        data.users.map((user) => {
          return {
            label: user.first_name + ' ' + user.last_name,
            value: user.id,
          };
        }) ?? []
      );
    },
  });

  useEffect(() => {
    setCurValue(value ?? null);
  }, [value]);

  if (loading) {
    return <p>loading</p>;
  }
  if (!data?.users) {
    return <p>Error loading</p>;
  }

  return (
    <IsoSelect
      showSearch
      onChange={(id) => onChange(name ?? 'user', id)}
      placeholder={placeholder}
      value={curValue}
      style={{ width: '100%' }}
      allowClear={true}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={users}
    />
  );
};

const GET_USERS = gql(`
  query users {
    users {
      id
      first_name
      last_name
      email
    }
  }
`);
