import type { AppDataActions } from '@app/containers/App/reducer';
import AppDataReducer, { AppDataActionType } from '@app/containers/App/reducer';
import options from '@app/containers/Sidebar/sidebarOptions';
import type { NavOptions } from '@app/settings/navigation';
import type { Dispatch, PropsWithChildren } from 'react';
import React, { createContext, useContext, useReducer } from 'react';

export type AppDataView = 'MobileView' | 'DesktopView' | 'TabView';

export interface AppDataState {
  collapsed: boolean;
  view: AppDataView;
  height: number;
  openDrawer: boolean;
  openKeys: string[];
  current: string[];
}

const getParent = (lastRoute: string) => {
  const parents: string[] = [];
  if (!lastRoute) return parents;
  for (const option of options as NavOptions) {
    if (option.children) {
      for (const child of option.children) {
        if (child.key === lastRoute) {
          parents.push(lastRoute);
          parents.push(option.key);
        }
      }
    }
  }
  return parents;
};

export function getDefaultPath() {
  if (window && window.location.pathname) {
    const routes = window.location.pathname.split('/');
    if (routes.length > 1) {
      const lastRoute = routes[routes.length - 1];
      return getParent(lastRoute);
    }
  }
  return [];
}

const preKeys = getDefaultPath();

export function getAppView(width: number): AppDataView {
  if (width > 1220) {
    return 'DesktopView';
  } else if (width > 767) {
    return 'TabView';
  }
  return 'MobileView';
}

const initialState: AppDataState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getAppView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
};

const AppDataContext = createContext<{
  state: AppDataState;
  dispatch: Dispatch<AppDataActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const AppDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(AppDataReducer, initialState);

  return (
    <AppDataContext.Provider value={{ state, dispatch }}>
      {children}
    </AppDataContext.Provider>
  );
};

const useAppData = () => {
  const context = useContext(AppDataContext);

  if (context === undefined) {
    throw new Error('useAppData must be used within AppDataContext');
  }

  const { state, dispatch } = context;

  const toggleAll = (width: number, height: number) => {
    const view = getAppView(width);
    const collapsed = view !== 'DesktopView';
    dispatch({
      type: AppDataActionType.TOGGLE_ALL,
      collapsed,
      view,
      height,
    });
  };
  const toggleCollapsed = () =>
    dispatch({
      type: AppDataActionType.COLLPSE_CHANGE,
    });
  const toggleOpenDrawer = () =>
    dispatch({
      type: AppDataActionType.COLLPSE_OPEN_DRAWER,
    });
  const changeOpenKeys = (openKeys: string[]) =>
    dispatch({
      type: AppDataActionType.CHANGE_OPEN_KEYS,
      openKeys,
    });
  const changeCurrent = (current: string[]) =>
    dispatch({
      type: AppDataActionType.CHANGE_CURRENT,
      current,
    });

  return {
    ...state,
    toggleAll,
    toggleCollapsed,
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
  };
};

export default useAppData;
