import saMessages from '@app/i18n/locales/fr_FR.json';
import antdSA from 'antd/lib/locale-provider/fr_FR';
// import appLocaleData from 'react-intl/locale-data/fr';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'fr-FR',
  // data: appLocaleData,
};
export default saLang;
