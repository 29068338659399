import { ModuleIcon } from '@app/components/module/moduleIcon';
import { IsoMenu, IsoMenuSubMenu } from '@app/components/uielements';
import IntlMessages from '@app/components/utility/intlMessages';
import type { CSSProperties } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface NavOption {
  key: string;
  label: string;
  type?: string;
  leftIcon?: string;
  withoutDashboard?: boolean;
  children?: {
    key: string;
    label: string;
    withoutDashboard: boolean;
  }[];
}

export type NavOptions = readonly NavOption[];

interface getMenuItemProps {
  singleOption: NavOption;
  submenuStyle: CSSProperties;
  submenuColor: CSSProperties;
}

const stripTrailingSlash = (str: string) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

const GetMenuItem = ({
  singleOption,
  submenuStyle,
  submenuColor,
}: getMenuItemProps) => {
  const { key, type, label, leftIcon, withoutDashboard, children } =
    singleOption;

  const { pathname } = useLocation();
  const url = stripTrailingSlash(pathname);

  const menuLinkTo = withoutDashboard
    ? key === 'home'
      ? '/dashboard'
      : `/dashboard/${key}`
    : `${url}/${key}`;

  if (children) {
    return (
      <IsoMenuSubMenu
        key={key}
        title={
          <span className="mainNavMenuItemder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        }
      >
        {children.map((child) => {
          const linkTo = child.withoutDashboard
            ? `/${child.key}`
            : `${url}/${child.key}`;
          return (
            <IsoMenu.Item style={submenuStyle} key={child.key}>
              <Link style={submenuColor} to={linkTo}>
                <IntlMessages id={child.label} />
              </Link>
            </IsoMenu.Item>
          );
        })}
      </IsoMenuSubMenu>
    );
  }
  return (
    <IsoMenu.Item key={key}>
      <Link to={menuLinkTo}>
        <span className="mainNavMenuItemder" style={submenuColor}>
          {type ? (
            <ModuleIcon size={12} type={type} />
          ) : (
            <i className={leftIcon} />
          )}
          <span className="nav-text">
            <IntlMessages id={label} />
          </span>
        </span>
      </Link>
    </IsoMenu.Item>
  );
};

export { GetMenuItem };

