import { useMutation, useQuery } from '@apollo/client';
import { notification } from '@app/components';
import { IsoDatePicker, IsoInput } from '@app/components/uielements';
import { SelectUser } from '@app/containers/Users/selectUser';
import { gql } from '@app/gqlgen';
import type { MyTasksQuery } from '@app/gqlgen/graphql';
import Firebase from '@app/helpers/firebase';
import { Button, Checkbox, Pagination, Switch } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface TaskListProps {
  tasks: MyTasksQuery['tasksConnection']['nodes'] | null | undefined;
}

const TaskList: FC<TaskListProps> = ({ tasks }) => {
  const [completeTask] = useMutation(COMPLETE_TASK_MUTATION, {
    refetchQueries: ['myTasks', 'myTotalTasks'],
  });

  const [uncompleteTask] = useMutation(UNCOMPLETE_TASK_MUTATION, {
    refetchQueries: ['myTasks', 'myTotalTasks'],
  });

  const [updateTask] = useMutation(UPDATE_TASK_MUTATION, {
    refetchQueries: ['myTasks', 'myTotalTasks'],
  });

  if (!tasks) {
    return <div>No Data!!</div>;
  }

  if (tasks.length === 0) {
    return <div>You are amazing. No Tasks here</div>;
  }

  return (
    <>
      {tasks.map((task) => {
        const dueDateMoment = moment(task.due_date);
        const isPastDue = dueDateMoment < moment();
        return (
          <div key={task.id} className="flex my-1">
            <span className="px-2">
              <Checkbox
                onChange={() =>
                  completeTask({
                    variables: {
                      id: task.id,
                    },
                  })
                }
              />
            </span>
            <span
              className={`border-2 ${isPastDue ? 'bg-red-300' : 'bg-sky-50'}`}
            >
              <IsoDatePicker
                suffixIcon={null}
                allowClear={false}
                bordered={false}
                value={dueDateMoment}
                onChange={(dateObject, dateString) => {
                  console.log(dateObject, dateString);
                  updateTask({
                    variables: {
                      id: task.id,
                      input: {
                        due_date: dateString,
                      },
                    },
                  });
                }}
                inputRender={(props) => (
                  <span {...props}>{dueDateMoment.fromNow()}</span>
                )}
              />
            </span>
            <span className="px-2 py-1">{task.name}</span>
            <span className="px-2">
              <SelectUser
                placeholder="Assigned User"
                value={task.assigned_user?.id ?? null}
                onChange={(name, value) => {
                  console.log(name, value);
                  updateTask({
                    variables: {
                      id: task.id,
                      input: {
                        assigned_user_id: value,
                      },
                    },
                  });
                }}
              />
            </span>
            <span className="ml-1 px-2 py-1 bg-green-200">{task.module}</span>
            <span className="ml-1 px-2 py-1">
              {task.module === 'account' ? (
                <Link to={`/dashboard/accounts/${task.module_id}`}>
                  {task.account?.name}
                </Link>
              ) : null}
              {task.module === 'deal' ? (
                <Link to={`/dashboard/deals/${task.module_id}`}>
                  {task.deal?.name ?? 'Deal?'} - {task.account?.name ?? 'AC?'}
                </Link>
              ) : null}
              {task.module === 'contact' ? (
                <Link to={`/dashboard/contacts/${task.module_id}`}>
                  {task.contact
                    ? `${task.contact.first_name} ${task.contact.last_name}`
                    : 'Contact'}
                </Link>
              ) : null}
              {task.module === 'purchase_order' ? (
                <Link to={`/dashboard/purchase_orders/${task.module_id}`}>
                  {task.purchase_order?.name ?? 'PO?'} -{' '}
                  {task.account?.name ?? 'AC?'}
                </Link>
              ) : null}
            </span>
            {task.contact ? (
              <span className="px-2">
                Contact: {task.contact.first_name} {task.contact.last_name}
              </span>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

interface ViewTasksProps {
  mineOnly?: boolean;
  showCompleted?: boolean;
  is_active?: boolean;
  limit?: number;
  account_id?: number;
}

export const ViewTasks: FC<ViewTasksProps> = ({
  mineOnly,
  showCompleted,
  limit,
  account_id,
}) => {
  const [name, setName] = useState('');
  const [dueDate, setDueDate] = useState(moment());
  const [assignedUser, setAssignedUser] = useState<string | null>(() => {
    const user = Firebase.user();
    return user && user.uid ? user.uid : null;
  });
  const [mineOnlyState, setMineOnlyState] = useState<boolean | undefined>(
    mineOnly
  );

  const { data, error, loading, refetch, variables } = useQuery(MY_TASKS, {
    variables: {
      mineOnly: mineOnlyState,
      showCompleted,
      limit,
      account_id,
    },
  });

  const [createTask] = useMutation(CREATE_TASK_MUTATION, {
    refetchQueries: ['myTasks'],
    onCompleted: (data) => {
      notification('success', 'Task Created', data.createTask.name);
      setName('');
    },
    onError: (error) => {
      notification('error', 'Problem creating task', error.message);
      console.log(error);
    },
  });

  useEffect(() => {
    setMineOnlyState(mineOnly);
    refetch();
  }, [mineOnly, refetch]);

  const createNewTask = () => {
    if (!name || name.trim() === '') {
      notification('error', 'Task name is required');
      return;
    }

    const dueDateString = dueDate.format('yyyy-MM-DD');
    console.log(dueDateString);

    createTask({
      variables: {
        input: {
          name,
          due_date: dueDateString,
          assigned_user_id: assignedUser,
          account_id: account_id,
        },
      },
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No Data!!</div>;
  }

  const pageInfo = data.tasksConnection.pageInfo;

  return (
    <div>
      <div className="flex justify-between bg-slate-300 border-b-1">
        {/* <IntlMessages id="sidebar.notification" /> ({pastDueCount}) */}
        <div className="flex">
          <h3>Tasks</h3>
          <div className="ml-2">
            {data?.tasksConnection ? (
              <p>
                ({data.tasksConnection.pastDueCount}/
                {data.tasksConnection.totalCount})
              </p>
            ) : null}
          </div>
        </div>
        <div>
          <Switch
            checked={mineOnlyState ?? false}
            onChange={(value) => {
              setMineOnlyState(value);
              refetch({
                ...variables,
                mineOnly: value,
              });
            }}
          />
          <span className="ml-2">Mine Only</span>
        </div>
      </div>
      <div className="flex">
        {account_id ? (
          <div className="w-full p-2 border-sky-500 bg-white">
            <div>
              <IsoInput
                placeholder="Task Name"
                size="small"
                className="w-1/2"
                value={name}
                onChange={(e) => {
                  console.log(e.target.value);
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="flex">
              <IsoDatePicker
                size="small"
                placeholder="Due Date"
                className="w-1/2"
                value={dueDate}
                onChange={(dateObject, dateString) => {
                  console.log(dateObject, dateString);
                  setDueDate(dateObject);
                }}
              />
              <SelectUser
                placeholder="Assigned User"
                value={assignedUser}
                onChange={(name, value) => {
                  console.log(name, value);
                  setAssignedUser(value);
                }}
              />
              <div className="mr-2">
                <Button type="primary" onClick={() => createNewTask()}>
                  Create Task
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Pagination
        size="small"
        current={pageInfo.page}
        pageSize={pageInfo.limit}
        total={pageInfo.total}
        showSizeChanger={true}
        onChange={(page, pageSize) => {
          console.log(page, pageSize);
          refetch({
            ...variables,
            limit: pageSize,
            page: page,
          });
        }}
      />
      <div className="w-full h-2/3 flex-col mb-5 flex">
        <TaskList tasks={data?.tasksConnection?.nodes} />
      </div>
    </div>
  );
};

const MY_TASKS = gql(`
query myTasks ($limit: Int, $page: Int, $mineOnly: Boolean, $showCompleted: Boolean,  $account_id: Int, $deal_id: Int, $contact_id: Int, $purchase_order_id: Int) {
	tasksConnection (limit: $limit, page: $page, mineOnly: $mineOnly, showCompleted: $showCompleted, account_id: $account_id, deal_id: $deal_id, contact_id: $contact_id, purchase_order_id: $purchase_order_id) {
		nodes {
		id
		name
    module
    module_id
		purchase_order_id
		due_date
		assigned_user {
			id
			first_name
			last_name
		}
		purchase_order {
			id
			name
		}
			account {
				id
				name
			}
			deal {
				id
				name
			}
			contact {
				id
				first_name
				last_name
			}
			created_at
			updated_at
	}
		pageInfo {
			total
			page
			limit
		}
		totalCount
		pastDueCount
	}
}
`);

const CREATE_TASK_MUTATION = gql(`
mutation createTask($input: TaskCreateInput!) {
  createTask(input: $input) {
    id
    name
  }
}
`);

const COMPLETE_TASK_MUTATION = gql(`
mutation completeTask($id: Int!) {
	completeTask(id: $id) {
		id
		name
		completed_at
	}
}
`);

const UNCOMPLETE_TASK_MUTATION = gql(`
mutation uncompleteTask($id: Int!) {
	uncompleteTask(id: $id) {
		id
		name
		completed_at
	}
}
`);

const UPDATE_TASK_MUTATION = gql(`
mutation updateTask($id: Int!, $input: TaskUpdateInput!) {
	updateTask(id: $id, input: $input) {
		id
		name
	}
}
`);
