import NavMain from '@app/containers/NavMain/NavMain';
import useThemeSwitcher from '@app/containers/ThemeSwitcher/context';
import { getCompany } from '@app/helpers/utility';
import { Layout, Tooltip } from 'antd';
import type { CSSProperties } from 'react';
import React from 'react';
import { FaCog, FaLink } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { AllSearch } from './allSearch';
import { TopbarWrapper } from './topbar.style';
import TopbarAddModule from './topbarAddModule';
import { TopbarNotification } from './topbarNotification';
import { TopbarTasks } from './topbarTasks';
import { TopbarUser } from './topbarUser';

const { Header } = Layout;
const iconSize = '28px';

export const TopbarCustom = () => {
  const { topbarTheme } = useThemeSwitcher();
  const { pathname: url } = useLocation();
  const styling: CSSProperties = {
    background: topbarTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    zIndex: 50,
  };
  const company = getCompany();
  return (
    <TopbarWrapper>
      <Header style={styling} className="isomorphicTopbar">
        <div id="topbar-info" className="header-split">
          <div className="logo topbar-left">
            <Link to="/dashboard">
              <h2>{company?.name}</h2>
            </Link>
          </div>
          <div className="topbar-center">
            <AllSearch />
          </div>
          <div className="topbar-right">
            <ul className="isoRight topbarSystemMenu">
              <li className="add-modules">
                <TopbarAddModule iconSize={iconSize} />
              </li>
              <li className="resources">
                <a
                  target="_blank"
                  href="https://www.dropbox.com/sh/g0qgl97adhbv6jm/AAB9cE9hOlvvIjiIT4wi39Bja?dl=0"
                  rel="noreferrer"
                >
                  <Tooltip
                    placement="bottom"
                    title={'Resources for CRM, Sales, Marketing, and more!'}
                  >
                    <FaLink className="topbar-icon" />
                  </Tooltip>
                </a>
              </li>
              <li className="topbar-icon">
                <TopbarTasks />
              </li>
              <li className="isoNotify">
                <TopbarNotification />
              </li>
              <li className="isoUser">
                <TopbarUser />
              </li>
              <li>
                <Link to="/dashboard/settings">
                  <FaCog className="topbar-icon" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="header-split">
          <NavMain />
        </div>
      </Header>
    </TopbarWrapper>
  );
};
