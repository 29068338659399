import { IsoMenu } from '@app/components/uielements';
import useAppData from '@app/containers/App/context';
import useThemeSwitcher from '@app/containers/ThemeSwitcher/context';
import { GetMenuItem } from '@app/settings/navigation';
import React from 'react';
import options from './mainOptions';

const MainNav = () => {
  const { sidebarTheme } = useThemeSwitcher();

  const { current, changeCurrent } = useAppData();

  const submenuStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: sidebarTheme.textColor,
  };
  const submenuColor = {
    color: sidebarTheme.textColor,
  };

  const handleClick = (e) => {
    changeCurrent([e.key]);
  };

  return (
    <IsoMenu
      onClick={handleClick}
      className="isoDashboardMenu"
      mode="horizontal"
      selectedKeys={current}
    >
      {options.map((singleOption) =>
        GetMenuItem({ submenuStyle, submenuColor, singleOption })
      )}
    </IsoMenu>
  );
};

export default MainNav;
