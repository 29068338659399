import type { NavOptions } from '@app/settings/navigation';

const options = [
  /*
	{
    key: 'leads',
    label: 'sidebar.leads',
		leftIcon: "ion-leaf",
		withoutDashboard: true
  },
  */
  {
    key: 'accounts',
    label: 'sidebar.accounts',
    leftIcon: 'ion-ios-paper',
    withoutDashboard: true,
  },
  {
    key: 'deals',
    label: 'sidebar.deals',
    leftIcon: 'ion-clipboard',
    withoutDashboard: true,
  },
  {
    key: 'campaigns',
    label: 'sidebar.campaigns',
    leftIcon: 'ion-flash',
    withoutDashboard: true,
  },
  {
    key: 'designcodes',
    label: 'sidebar.design_codes',
    leftIcon: 'ion-pull-request',
    withoutDashboard: true,
  },
  {
    key: 'artcodes',
    label: 'sidebar.art_codes',
    leftIcon: 'ion-tshirt-outline',
    withoutDashboard: true,
  },
  {
    key: 'styles',
    label: 'sidebar.styles',
    leftIcon: 'ion-tshirt-outline',
    withoutDashboard: true,
  },
  {
    key: 'forecasts',
    label: 'sidebar.forecasts',
    leftIcon: 'ion-social-usd-outline',
  },
  {
    key: 'artjobs',
    label: 'sidebar.artjobs',
    leftIcon: 'ion-clipboard',
  },
  {
    key: 'teamstores',
    label: 'sidebar.teamstores',
    leftIcon: 'ion-ios-people-outline',
  },
  {
    key: 'purchaseorders',
    label: 'sidebar.purchaseorders',
    leftIcon: 'ion-social-euro',
  },
  {
    key: 'salesorders',
    label: 'sidebar.salesorders',
    leftIcon: 'ion-social-euro',
  },
  {
    key: 'npssurveys',
    label: 'sidebar.npssurvey',
    leftIcon: 'ion-social-euro',
  },
] as const satisfies NavOptions;

export default options;
