import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { App } from './containers/App/App';
import { asyncNamedComponent } from './helpers/AsyncFunc';
import Firebase from './helpers/firebase';
import { getCompany } from './helpers/utility';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const user = Firebase.user();
  const company = getCompany();
  console.log('Router firebase', user);
  console.log('Router company', company);
  return (
    <Route
      {...rest}
      render={(props) =>
        user && company ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/companyselect',
              state: { location },
            }}
          />
        )
      }
    />
  );
};

const CompanyRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const user = Firebase.user();
  console.log('company user check', user);
  return (
    <Route
      exact
      path={'/companyselect'}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PublicRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={'/'}
          component={asyncNamedComponent(async () => {
            console.log('sign in component router');
            const { SignIn } = await import('./containers/Page/signin');
            return SignIn;
          })}
        />
        <Route
          exact
          path={'/404'}
          component={asyncNamedComponent(async () => {
            const { Page404 } = await import('./containers/Page/404');
            return Page404;
          })}
        />
        <Route
          exact
          path={'/500'}
          component={asyncNamedComponent(async () => {
            const { Page500 } = await import('./containers/Page/500');
            return Page500;
          })}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncNamedComponent(async () => {
            const { SignIn } = await import('./containers/Page/signin');
            return SignIn;
          })}
        />
        <Route
          exact
          path={'/signup'}
          component={asyncNamedComponent(async () => {
            const { SignUp } = await import('./containers/Page/signup');
            return SignUp;
          })}
        />
        <Route
          exact
          path={'/forgotpassword'}
          component={asyncNamedComponent(async () => {
            const { ForgotPassword } = await import(
              './containers/Page/forgotPassword'
            );
            return ForgotPassword;
          })}
        />
        <Route
          exact
          path={'/resetpassword'}
          component={asyncNamedComponent(async () => {
            const { ResetPassword } = await import(
              './containers/Page/resetPassword'
            );
            return ResetPassword;
          })}
        />
        <CompanyRoute
          exact
          path={'/companyselect'}
          component={asyncNamedComponent(async () => {
            const { CompanySelect } = await import(
              './containers/Prodo/companySelect'
            );
            return CompanySelect;
          })}
        />
        <RestrictedRoute path="/dashboard" component={App} />
      </Switch>
    </BrowserRouter>
  );
};

export { PublicRoutes };
