import type { AppDataState, AppDataView } from './context';

export const enum AppDataActionType {
  COLLPSE_CHANGE = 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER = 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS = 'CHANGE_OPEN_KEYS',
  TOGGLE_ALL = 'TOGGLE_ALL',
  CHANGE_CURRENT = 'CHANGE_CURRENT',
}

export type AppDataActions =
  | {
      type: AppDataActionType.COLLPSE_CHANGE;
    }
  | {
      type: AppDataActionType.TOGGLE_ALL;
      collapsed: boolean;
      view: AppDataView;
      height: number;
    }
  | {
      type: AppDataActionType.COLLPSE_OPEN_DRAWER;
    }
  | {
      type: AppDataActionType.CHANGE_OPEN_KEYS;
      openKeys: string[];
    }
  | {
      type: AppDataActionType.CHANGE_CURRENT;
      current: string[];
    };

export default function appReducer(
  state: AppDataState,
  action: AppDataActions
) {
  switch (action.type) {
    case AppDataActionType.COLLPSE_CHANGE:
      return { ...state, collapsed: state.collapsed };
    case AppDataActionType.COLLPSE_OPEN_DRAWER:
      return { ...state, openDrawer: !state.openDrawer };
    case AppDataActionType.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case AppDataActionType.CHANGE_OPEN_KEYS:
      return { ...state, openKeys: action.openKeys };
    case AppDataActionType.CHANGE_CURRENT:
      return { ...state, current: action.current };
    default:
      return state;
  }
  return state;
}
