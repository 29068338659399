import useLanguageSwitcher from '@app/containers/LanguageSwitcher/context';
import { TopbarCustom } from '@app/containers/Topbar/TopbarCustom';
import { getCompany } from '@app/helpers/utility';
import AppLocale from '@app/i18n';
import { siteConfig } from '@app/settings';
import themes from '@app/settings/themes';
import { ConfigProvider, Layout } from 'antd';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { AppRouter } from './AppRouter';
import AppHolderStyled from './commonStyle';
import { AppDataProvider } from './context';
import './global.css';

// import { Debounce } from 'react-throttle';
// import WindowResizeListener from 'react-window-size-listener';

const { Content, Footer } = Layout;

export const App = () => {
  const company = getCompany();
  const { pathname: url } = useLocation();

  const { language } = useLanguageSwitcher();
  const { locale } = language;

  const currentAppLocale = AppLocale[locale];
  if (!company) {
    return <p>Company not selected</p>;
  }

  console.log('App pathname check', url);

  return (
    <AppDataProvider>
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes['themedefault']}>
            <AppHolderStyled>
              <Layout style={{ height: '100vh' }}>
                {/*
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={(windowSize) =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                  */}
                <TopbarCustom />
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative',
                  }}
                >
                  <AppRouter url={url} />
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed',
                  }}
                >
                  {siteConfig.footerText}
                </Footer>
              </Layout>
            </AppHolderStyled>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    </AppDataProvider>
  );
};
