import { notification } from 'antd';
import type { ReactNode } from 'react';

export const createNotification = (
  type: 'success' | 'error' | 'info' | 'warning' | 'open',
  message: ReactNode,
  description?: ReactNode,
  options?: {
    duration?: number | null;
    placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
    onClick?: () => void;
  }
) => {
  notification[type]({
    message,
    description,
    duration:
      options?.duration != undefined
        ? options.duration
        : type === 'error'
        ? 0
        : 3,
    placement: options?.placement ?? 'topRight',
    onClick: options?.onClick,
  });
};
