// Need to convert to v9 of firebase. using backward compatable v8 with v9 version
import { firebaseConfig } from '@app/settings';
import { notification } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';

export const enum FirebaseProvider {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  GITHUB = 'github',
  TWITTER = 'twitter',
}

interface LoginInfo {
  email: string;
  password: string;
}
const valid =
  firebaseConfig && firebaseConfig.apiKey && firebaseConfig.projectId;

firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebase.auth;
let firebaseMessage: firebase.messaging.Messaging | null = null;
if (firebase.messaging.isSupported()) {
  firebaseMessage = firebase.messaging();
  firebaseMessage.onMessage((payload) => {
    console.log('on message: ', payload);
    if (payload.notification) {
      notification.info({
        message: payload.notification.title,
        description: payload.notification.body,
      });
    }
  });
}

class FirebaseHelper {
  isValid = valid;

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.signup = this.signup.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.doPasswordUpdate = this.doPasswordUpdate.bind(this);
    this.auth = this.auth.bind(this);
    //this.rsf = new ReduxSagaFirebase(firebaseApp, firebase.firestore());
    //this.rsfFirestore = this.rsf.firestore;
  }

  messaging() {
    return firebaseMessage;
  }

  token = () => {
    const user = this.user();
    if (user) {
      return user.getIdToken().then((token) => {
        return token;
      });
    } else {
      return null;
    }
  };

  user() {
    return firebaseAuth().currentUser;
  }

  login(provider: FirebaseProvider, info: LoginInfo) {
    switch (provider) {
      case FirebaseProvider.EMAIL:
        return firebaseAuth().signInWithEmailAndPassword(
          info.email,
          info.password
        );
      /*
      case this.FACEBOOK:
        return firebaseAuth().FacebookAuthProvider();
      case this.GOOGLE:
        var googleProvider = new firebase.auth.GoogleAuthProvider();
        return firebaseAuth().signInWithPopup(googleProvider);
      case this.GITHUB:
        return firebaseAuth().GithubAuthProvider();
      case this.TWITTER:
        return firebaseAuth().TwitterAuthProvider();
        */
      default:
    }
  }

  signup(provider: FirebaseProvider, info: LoginInfo) {
    switch (provider) {
      case FirebaseProvider.EMAIL:
        return firebaseAuth().createUserWithEmailAndPassword(
          info.email,
          info.password
        );
      /*
      case this.FACEBOOK:
        return firebaseAuth().FacebookAuthProvider();
      case this.GOOGLE:
        return firebaseAuth().GoogleAuthProvider();
      case this.GITHUB:
        return firebaseAuth().GithubAuthProvider();
      case this.TWITTER:
        return firebaseAuth().TwitterAuthProvider();
        */
      default:
        alert('defaulted');
    }
  }

  logout() {
    return firebaseAuth().signOut();
  }

  auth = () => {
    return firebaseAuth();
  };
  resetPassword(email: string) {
    return firebaseAuth().sendPasswordResetEmail(email);
  }

  doPasswordUpdate(password: string) {
    firebaseAuth().currentUser?.updatePassword(password);
  }

  createNewRef() {
    return firebase.database().ref().push().key;
  }
}

export default new FirebaseHelper();
