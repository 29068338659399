export interface ThemeOption {
  themeName: string;
  buttonColor: string;
  textColor?: string;
  backgroundColor?: string;
}

type ThemeOptionDefault = ThemeOption & {
  themeName: 'themedefault';
};

export type Theme = {
  id: string;
  label: string;
  defaultOption: ThemeOptionDefault;
  options?: ReadonlyArray<ThemeOption>;
};

const coreTheme = {
  id: 'coreTheme',
  label: 'themeSwitcher',
  defaultOption: {
    themeName: 'themedefault',
    buttonColor: '#ffffff',
    textColor: '#323332',
  },
  options: [
    {
      themeName: 'theme2',
      buttonColor: '#ffffff',
      textColor: '#323332',
    },
  ],
} as const satisfies Theme;

const topbarTheme = {
  id: 'topbarTheme',
  label: 'themeSwitcher.Topbar',
  defaultOption: {
    themeName: 'themedefault',
    buttonColor: '#ffffff',
    textColor: '#323332',
  },
  options: [
    {
      themeName: 'theme1',
      buttonColor: '#e0364c',
      backgroundColor: '#e0364c',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme2',
      buttonColor: '#6534ff',
      backgroundColor: '#6534ff',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme3',
      buttonColor: '#4482FF',
      backgroundColor: '#4482FF',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme4',
      buttonColor: '#422e62',
      backgroundColor: '#422e62',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme5',
      buttonColor: '#22144c',
      backgroundColor: '#22144c',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme6',
      buttonColor: '#4670a2',
      backgroundColor: '#4670a2',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme7',
      buttonColor: '#494982',
      backgroundColor: '#494982',
      textColor: '#ffffff',
    },
  ],
} as const satisfies Theme;

const sidebarTheme = {
  id: 'sidebarTheme',
  label: 'themeSwitcher.Sidebar',
  defaultOption: {
    themeName: 'themedefault',
    buttonColor: '#323332',
    backgroundColor: undefined,
    textColor: '#788195',
  },
  options: [
    {
      themeName: 'theme1',
      buttonColor: '#e0364c',
      backgroundColor: '#e0364c',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme2',
      buttonColor: '#6534ff',
      backgroundColor: '#6534ff',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme3',
      buttonColor: '#4482FF',
      backgroundColor: '#4482FF',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme4',
      buttonColor: '#422e62',
      backgroundColor: '#422e62',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme5',
      buttonColor: '#22144c',
      backgroundColor: '#22144c',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme6',
      buttonColor: '#4670a2',
      backgroundColor: '#4670a2',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme7',
      buttonColor: '#494982',
      backgroundColor: '#494982',
      textColor: '#ffffff',
    },
  ],
} as const satisfies Theme;

const layoutTheme = {
  id: 'layoutTheme',
  label: 'themeSwitcher.Background',
  defaultOption: {
    themeName: 'themedefault',
    buttonColor: '#ffffff',
    backgroundColor: '#F1F3F6',
    textColor: undefined,
  },
  options: [
    {
      themeName: 'theme1',
      buttonColor: '#ffffff',
      backgroundColor: '#ffffff',
      textColor: '#323232',
    },
    {
      themeName: 'theme2',
      buttonColor: '#F9F9F9',
      backgroundColor: '#F9F9F9',
      textColor: '#ffffff',
    },
    {
      themeName: 'theme3',
      buttonColor: '#ebebeb',
      backgroundColor: '#ebebeb',
      textColor: '#ffffff',
    },
  ],
} as const satisfies Theme;

const customThemes = {
  coreTheme,
  topbarTheme,
  sidebarTheme,
  layoutTheme,
};

export type CustomizedThemeNames = keyof typeof customThemes;

export const getCurrentTheme = (
  attribute: CustomizedThemeNames,
  selectedThemename?: string
): ThemeOption => {
  if (selectedThemename && customThemes[attribute].options) {
    for (let themeOption of customThemes[attribute].options) {
      if (themeOption.themeName === selectedThemename) {
        return themeOption;
      }
    }
  }
  return customThemes[attribute].defaultOption;
};

export default customThemes;
