import { gql, useMutation, useQuery } from '@apollo/client';
import { notification } from '@app/components/index';
import IntlMessages from '@app/components/utility/intlMessages';
import { Popover, Spin } from 'antd';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { FaBell } from 'react-icons/fa';
import TopbarDropdownWrapper from './topbarDropdown.style';

export const TopbarNotification = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState<ReactNode[]>([]);

  const { loading } = useQuery(myNotifications, {
    onCompleted: (data) => {
      console.log('myNotifications data', data);
      setNotifications(
        data.me.notifications.map((not) => {
          return (
            <button
              className={
                'isoDropdownListItem border-none ' +
                (not.read_at ? '' : 'unread')
              }
              key={not.id}
            >
              <h5>{not.title}</h5>
              <p>{not.body}</p>
            </button>
          );
        })
      );
      setUnreadCount(
        data.me.notifications.filter((n) => n.read_at === null).length
      );
    },
  });

  const [readAllNotifications] = useMutation(readAllNotificationMutation, {
    refetchQueries: ['myNotifications'],
    onCompleted: (data) => {
      console.log('readAllNotifications data', data);
      setUnreadCount(0);
    },
    onError: (error) => {
      console.log('readAllNotifications error', error);
      notification('error', error);
    },
  });

  const content = (
    <TopbarDropdownWrapper className="topbarNotification">
      <div className="isoDropdownHeader">
        <h3>
          <IntlMessages id="sidebar.notification" /> ({unreadCount})
        </h3>
      </div>
      <div className="isoDropdownBody">
        {loading ? <Spin /> : notifications}
      </div>
      <button
        className="isoViewAllBtn border-none"
        onClick={() => readAllNotifications()}
      >
        Mark all as read
      </button>
    </TopbarDropdownWrapper>
  );
  return (
    <Popover content={content} placement="bottomLeft">
      <FaBell className="topbar-icon" />
    </Popover>
  );
};

const myNotifications = gql(`
  query myNotifications {
    me {
      notifications {
        id
        event_id
        title
        body
        read_at
        created_at
      }
    }
  }
`);

const readAllNotificationMutation = gql(`
  mutation readAll {
    readAllNotifications
  }
`);
