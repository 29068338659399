import saMessages from '@app/i18n/locales/it_IT.json';
import antdSA from 'antd/lib/locale-provider/it_IT';
// import appLocaleData from 'react-intl/locale-data/it';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'it-IT',
  // data: appLocaleData,
};
export default saLang;
