import type {
  CustomizedThemeNames,
  ThemeOption,
} from '@app/containers/ThemeSwitcher/config';
import type { ThemeSwitcherState } from './context';

export const enum ThemeSwitcherActionType {
  CHANGE_THEME = 'CHANGE_THEME',
  SWITCH_ACTIVATION = 'SWITCH_ACTIVATION',
}

export type ThemeSwitcherAction =
  | {
      type: ThemeSwitcherActionType.SWITCH_ACTIVATION;
    }
  | {
      type: ThemeSwitcherActionType.CHANGE_THEME;
      attribute: CustomizedThemeNames;
      theme: ThemeOption;
    };

const ThemeSwitcherReducer = (
  state: ThemeSwitcherState,
  action: ThemeSwitcherAction
) => {
  switch (action.type) {
    case ThemeSwitcherActionType.SWITCH_ACTIVATION:
      return {
        ...state,
        isActivated: !state.isActivated,
      };
    case ThemeSwitcherActionType.CHANGE_THEME:
      return { ...state, [action.attribute]: action.theme };
    default:
      return state;
  }
};

export default ThemeSwitcherReducer;
