import type { LanguageOption } from '@app/containers/LanguageSwitcher/config';
import type { LanguageSwitcherState } from './context';

export const enum ActionType {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  ACTIVATE_LANG_MODAL = 'ACTIVATE_LANG_MODAL',
}

export type LanguageSwitcherAction =
  | { type: ActionType.ACTIVATE_LANG_MODAL }
  | { type: ActionType.CHANGE_LANGUAGE; language: LanguageOption };

const languageSwitcherReducer = (
  state: LanguageSwitcherState,
  action: LanguageSwitcherAction
) => {
  switch (action.type) {
    case ActionType.ACTIVATE_LANG_MODAL:
      return { ...state, isActivated: !state.isActivated };
    case ActionType.CHANGE_LANGUAGE:
      return { ...state, language: action.language };
    default:
      return state;
  }
};

export default languageSwitcherReducer;
