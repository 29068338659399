import { ModuleListItem } from '@app/components/module/moduleListItem';
import { IsoPopover } from '@app/components/uielements';
import { List } from 'antd';
import React, { Component } from 'react';
import { FaPlus } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

const moduleLinks = [
  { name: 'Account', type: 'Account', url: '/dashboard/accounts/new' },
  { name: 'Deal', type: 'Deal', url: '/dashboard/deals/new' },
  { full_name: 'Contact', type: 'Contact', url: '/dashboard/contacts/new' },
  {
    name: 'Sales Order',
    type: 'SalesReceipt',
    url: '/dashboard/salesorders/new',
  },
];

class TopbarAddModules extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  itemClick(url) {
    this.setState({ visible: !this.state.visible });
    this.props.history.push(url);
  }

  render() {
    const content = (
      <div className="topbarAddModule">
        <List
          bordered
          dataSource={moduleLinks}
          renderItem={(item) => (
            <ModuleListItem
              url={item.url}
              item={item}
              style={{ cursor: 'pointer' }}
            />
          )}
        />
      </div>
    );

    return (
      <IsoPopover
        content={content}
        trigger="click"
        open={this.state.visible}
        onOpenChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <FaPlus className="topbar-icon" />
      </IsoPopover>
    );
  }
}
export default withRouter(TopbarAddModules);
