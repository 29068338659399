import chineseLang from '@app/image/flag/china.svg';
import frenchLang from '@app/image/flag/france.svg';
import italianLang from '@app/image/flag/italy.svg';
import spanishLang from '@app/image/flag/spain.svg';
import englishLang from '@app/image/flag/uk.svg';
import { language } from '@app/settings';

export interface LanguageOption {
  languageId: string;
  locale: string;
  text: string;
  icon: string;
}

export interface LanguageConfig {
  defaultLanguage: string;
  options: readonly LanguageOption[];
}

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      icon: englishLang,
    },
    {
      languageId: 'chinese',
      locale: 'zh',
      text: 'Chinese',
      icon: chineseLang,
    },
    {
      languageId: 'spanish',
      locale: 'es',
      text: 'Spanish',
      icon: spanishLang,
    },
    {
      languageId: 'french',
      locale: 'fr',
      text: 'French',
      icon: frenchLang,
    },
    {
      languageId: 'italian',
      locale: 'it',
      text: 'Italian',
      icon: italianLang,
    },
  ],
} as const satisfies LanguageConfig;

export type LanguageConfigNames =
  typeof config['options'][number]['languageId'];

export const getCurrentLanguage = (lang: string) => {
  let selecetedLanguage: LanguageOption = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
};

export default config;
