import type { Company } from './restEndpoint';

export function getCompany(): Company | null {
  const storageCompany = localStorage.getItem('prodo_company');
  if (!storageCompany) return null;
  const company_info: Company = JSON.parse(storageCompany);
  if (company_info) {
    return company_info;
  }
  return null;
}

export function setCompany(company: Company): void {
  try {
    localStorage.setItem('prodo_company', JSON.stringify(company));
  } catch (e) {
    console.log('cannot set prodo_company', e);
  }
}

export function clearCompany(): void {
  try {
    localStorage.removeItem('prodo_company');
  } catch (e) {
    console.log('cannot remove prodo_company', e);
  }
}

export function clearToken(): void {
  try {
    localStorage.removeItem('id_token');
  } catch (e) {
    console.log('cannot remove id token', e);
  }
}

export function getToken(): string | null {
  try {
    const idToken = localStorage.getItem('id_token');
    return idToken;
  } catch (e) {
    console.log('cannot get token', e);
    clearToken();
  }
  return null;
}

export function isEmptyObject(obj: Object): boolean {
  for (let i in obj) return false;
  return true;
}

export function timeDifference(date: string | number | Date) {
  const givenTime = new Date(date);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 's' : '';
  };
  const number = (num) => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value: any, defValue = 0) {
  if (!value) {
    return 0;
  }
  const checkValue = Number(value);
  if (!isNaN(checkValue)) {
    return checkValue;
  }
  return defValue;
}

export function stringToPosetiveInt(value: string, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export const distinctArray = (
  data: { id?: number | null; name?: string | null; sort?: string | null }[],
  name: string
) => {
  const nameCheck = {};
  const filters = data.reduce(
    (acc: { text: string; value: number; sort: string }[], row) => {
      // return { text, value } if not added
      if (!row[name] || !row[name].name) {
        return acc;
      }
      if (!nameCheck[row[name].id]) {
        acc.push({
          text: row[name].name,
          value: row[name].id,
          sort: row[name].sort,
        });
        nameCheck[row[name].id] = true;
      }
      return acc;
    },
    []
  );
  return filters.slice().sort((a, b) => {
    if (a.sort && b.sort) {
      if (a.sort > b.sort) return 1;
      if (a.sort < b.sort) return -1;
    }
    if (a.value > b.value) return 1;
    if (a.value < b.value) return -1;
    return 0;
  });
};

export const dateStringSorter = (
  a: string | null | undefined,
  b: string | null | undefined
) => {
  if (!a && !b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) return 1;

  const testA = new Date(a);
  const testB = new Date(b);

  return testA > testB ? -1 : testA < testB ? 1 : 0;
};

export const stringSorter = (
  a: string | null | undefined,
  b: string | null | undefined
) => {
  if (!a && !b) {
    return 0;
  }
  if (!b) {
    return -1;
  }
  if (!a) return 1;
  if (a.toLowerCase() < b.toLowerCase()) return -1;
  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
};

export const readBlob = (file) => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing file'));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(file);
  });
};
