import { useQuery } from '@apollo/client';
import { ViewTasks } from '@app/containers/Tasks/ViewTasks';
import { gql } from '@app/gqlgen';
import { Badge, Popover } from 'antd';
import React from 'react';
import { FaClipboardList } from 'react-icons/fa';

export const TopbarTasks = () => {
  // const [pastDueCount, setPastDueCount] = useState(0);
  // const [tasks, setTasks] = useState<ReactNode[]>([]);

  const { data, loading, error } = useQuery(MY_TOTAL_TASKS);

  const content = (
    <div className="w-[75vw]">
      <ViewTasks mineOnly={true} showCompleted={false} limit={20} />
      {/* <button
        className="isoViewAllBtn border-none"
        onClick={() => readAllNotifications()}
      >
        Mark all as read
      </button> */}
    </div>
  );
  return (
    <Popover content={content} placement="bottomLeft">
      <Badge
        overflowCount={10}
        count={data?.tasksConnection.pastDueCount}
        size="small"
      >
        <FaClipboardList className="topbar-icon" />
      </Badge>
    </Popover>
  );
};

const MY_TOTAL_TASKS = gql(`
query myTotalTasks {
	tasksConnection (mineOnly: true, showCompleted: false) {
		totalCount
		pastDueCount
	}
}
`);
