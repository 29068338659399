import { LanguageSwitcherProvider } from '@app/containers/LanguageSwitcher/context';
import { ThemeSwitcherProvider } from '@app/containers/ThemeSwitcher/context';
import 'antd/dist/antd.min.css';
import React from 'react';
import { render } from 'react-dom';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import DashApp from './dashApp';
import './global.style.css';
import registerServiceWorker from './registerServiceWorker';

render(
  <LanguageSwitcherProvider>
    <ThemeSwitcherProvider>
      <DashApp />
    </ThemeSwitcherProvider>
  </LanguageSwitcherProvider>,
  document.getElementById('root')
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./dashApp', () => {
    const NextApp = require('./dashApp').default;
    render(
      <LanguageSwitcherProvider>
        <ThemeSwitcherProvider>
          <NextApp />
        </ThemeSwitcherProvider>
      </LanguageSwitcherProvider>,
      document.getElementById('root')
    );
  });
}
registerServiceWorker();
