import type { CSSProperties } from 'react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './notfound';
import { routes } from './router';

interface AppRouterProps {
  url?: string;
  style?: CSSProperties;
}

export const AppRouter = ({ style }: AppRouterProps) => {
  return (
    <div id="testing-here" className="testing" style={style}>
      <Switch>
        {routes.map((singleRoute) => {
          const { path, ...otherProps } = singleRoute;
          return (
            <Route
              exact={true}
              key={singleRoute.path}
              path={`/dashboard/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
        {/* This route will match any unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};
