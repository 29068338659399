import styled from 'styled-components';
import { palette } from 'styled-theme';

const Menus = (ComponentName) => styled(ComponentName)`
  .ant-dropdown-menu-item {
    a {
      font-size: 13px;
      color: ${palette('text', 1)};
    }
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    a {
      font-size: 13px;
      color: ${palette('text', 1)};
    }

    &:hover {
      background-color: ${palette('secondary', 1)};
    }
  }
`;

export { Menus };
